import React from 'react';
import PropTypes from 'prop-types';
import {useStaticQuery, graphql} from 'gatsby';
import {Column, Columns, Container} from 'bloomer';
import {GlobalStyleLayout} from './styles';

import {GlobalStyle} from '../../styles/GlobalStyles';
import {GlobalStyleCookieThough} from '../../styles/CookieThough';
import {FontsNames} from '../../styles/fonts';
import {Helmetscript} from '../Helmet';

import Header from '../Header/index';
import '../../styles/bulma/css/bulma.min.css';
import '../../styles/animate.css/animate.css';
import {FooterComp} from '../Footer';
import Icowhatsapp from '../../images/whatsapp_social.svg';

const Layout = ({children, typeRouteMenu}) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  return (
    <div className={`layout`} id={'classname-layout'}>
      <Helmetscript>
      <GlobalStyle/>
      <FontsNames/>
      <GlobalStyleLayout/>
      <GlobalStyleCookieThough/>

      <Header typeRouteMenu={typeRouteMenu}/>

      <Container isFluid className="wrapper-pages">
        <div id="button_whatsapp" class="right title">
          <a
            href="https://api.whatsapp.com/send?phone=5534543678"
            id="button_floating"
            class="widget_bt1 right"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={Icowhatsapp} alt={''} id="widget_logo"/>
            <span id="floating_button_sirena_span" class="">¡Escríbenos!</span>
          </a>
        </div>
        <Columns className="columns-responsive full-height" isMultiline={true} isCentered={true}>
          <Column className={'padding-no'} isSize={{mobile: 12, tablet: 12, desktop: 12, widescreen: 12}}>
            {children}
          </Column>
        </Columns>
      </Container>

      <FooterComp/>
      </Helmetscript>
    </div>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
