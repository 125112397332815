import React from 'react';
import Helmet from "react-helmet";
import { Script, withPrefix } from "gatsby";
export const Helmetscript = ({ children }) => {
  return (
    <div id={'container-helmet'}>
      <Helmet>
        <script src="https://www.googleoptimize.com/optimize.js?id=OPT-K5PZJPV"></script>
        <script src="https://www.googletagmanager.com/gtag/js?id=G-F0TJ32735E"></script>
        <script>
          {`
          (function(h,o,t,j,a,r) { 
            h.hj = h.hj || function(){(h.hj.q=h.hj.q||[]).push(arguments)};
              h._hjSettings={hjid:3186593,hjsv:6};
              a=o.getElementsByTagName('head')[0];
              r=o.createElement('script');r.async=1;
              r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
              a.appendChild(r);
          })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
          
          
          window.dataLayer = window.dataLayer || [];
          function gtag(){
            dataLayer.push(arguments);
          }
          gtag('js', new Date());
          gtag('config', 'G-F0TJ32735E');
          
          !function(f,b,e,v,n,t,s){if(f.fbq)return;n=f.fbq=function(){n.callMethod ? n.callMethod.apply(n,arguments):n.queue.push(arguments)};
          if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
          n.queue=[];t=b.createElement(e);t.async=!0;
          t.src=v;s=b.getElementsByTagName(e)[0];
          s.parentNode.insertBefore(t,s)}(window, document,'script',
          'https://connect.facebook.net/en_US/fbevents.js');
          fbq('init', '469745241582849');
          fbq('track', 'PageView');
        `}
        </script>
        <script>
          {`
          (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
          new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
          'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
          })(window,document,'script','dataLayer','GTM-5NXJXPF');
        `}
        </script>
        <script>
          {`
          (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
          new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
          'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
          })(window,document,'script','dataLayer','GTM-57TPZD5');
        `}
        </script>
        <noscript>
          {`
          <img height="1" width="1" style="display:none"
          src="https://www.facebook.com/tr?id=469745241582849&ev=PageView&noscript=1"
          />
          `}
        </noscript>
        <noscript>
        {`
          <iframe src="https://www.googletagmanager.com/ns.html?id=GTM-5NXJXPF" height="0" width="0" style="display:none;visibility:hidden"></iframe>
        `}
        </noscript>
        <noscript>
        {`
          <iframe src="https://www.googletagmanager.com/ns.html?id=GTM-57TPZD5" height="0" width="0" style="display:none;visibility:hidden"></iframe>
        `}
        </noscript>
      </Helmet>
      {children}
    </div>
  );
};