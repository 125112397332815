import * as React from "react"
import PropTypes from "prop-types"
import scrollTo from 'gatsby-plugin-smoothscroll';
import icoBell from '../../images/user_icon.svg';
import {Ico} from './styles';
import {BtnAniLink, GlobalStyle, MyAniLink} from './stylesItemMenuDesk';
import {GlobalBackgroundColors, GlobalColors} from "../../styles/GlobalStyles";

const ItemMenuDesk = ({itemText, linkTo, typeItem, typeRouteMenu, Bhover = true, showIcon}) => {

  // console.log('typeRouteMenu =>', typeRouteMenu);

  const isBrowser = typeof window !== "undefined";

  const fnGoToElement = (el) => {
    //console.log('el =>', el);
    if (isBrowser) {
      if(el === 'https://web.callmatik.com/panel/es/login'){
        window.location.href = el;
      } else if (el === 'https://blog.callmatik.com') {
        window.open(el, '_blank');
      } else if(el === '/registrate'){
        window.location.href = el;
      }
      setTimeout(function(){
        if(el !== 'https://blog.callmatik.com') {
          scrollTo(`#${el}`);
        }
      }, 1000);
    }
  }

  return (
    <>
      {typeRouteMenu === '#'
        ? (
          <BtnAniLink
            cover
            bg={GlobalBackgroundColors.bgContentLight}
            color={(typeItem == 'normal' || typeItem == 'linkExt') ? GlobalColors.colorMenuPrimary : (typeItem == 'round2')? GlobalColors.colorLight : GlobalColors.colorLight}
            Bhover={(typeItem == 'normal' || typeItem == 'linkExt') ? false : true}
            to={typeItem == 'normal' ? (`${typeRouteMenu}${linkTo}`) : linkTo}
            exit={{length: 0.5, state: {urlSection: linkTo}}}
            entry={{delay: 0.5, state: {urlSection: linkTo}}}
            state={{urlSection: linkTo}}
            onClick={() => fnGoToElement(linkTo)}
            typeItem={typeItem}
          >
            <GlobalStyle/>
            {showIcon?<Ico><img src={icoBell} alt={''}/></Ico>: <></>}
            {itemText}
          </BtnAniLink>
        ) : (
          <MyAniLink
            cover
            bg={GlobalBackgroundColors.bgContentLight}
            color={(typeItem == 'normal' || typeItem == 'linkExt') ? GlobalColors.colorMenuPrimary : GlobalColors.colorLight}
            // to={`/${linkTo}`}
            to={(typeItem == 'normal' || typeItem == 'linkExt') ? (`${typeRouteMenu}${linkTo}`) : linkTo}
            exit={{length: 0.5, state: {urlSection: linkTo}}}
            entry={{delay: 0.5, state: {urlSection: linkTo}}}
            state={{urlSection: linkTo}}
            onClick={() => fnGoToElement(linkTo)}
            Bhover={(typeItem == 'normal')?false:true}
            typeItem={typeItem}
          >
            <GlobalStyle/>
            {showIcon?<Ico><img src={icoBell} alt={''}/></Ico>: <></>}
            {itemText}
          </MyAniLink>
        )}
    </>
  )
}

ItemMenuDesk.propTypes = {
  itemText: PropTypes.node.isRequired,
  linkTo: PropTypes.node.isRequired,
}

export default ItemMenuDesk
