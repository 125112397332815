import { createGlobalStyle } from 'styled-components'

import MulishLightEot from '../fonts/MulishLight.eot';
import MulishLightTtf from '../fonts/MulishLight.ttf';
import MulishLightWoff from '../fonts/MulishLight.woff';
import MulishLightWoff2 from '../fonts/MulishLight.woff2';
import MulishLightSvg from '../fonts/MulishLight.svg';

import MulishRegularEot from '../fonts/MulishRegular.eot';
import MulishRegularTtf from '../fonts/MulishRegular.ttf';
import MulishRegularWoff from '../fonts/MulishRegular.woff';
import MulishRegularWoff2 from '../fonts/MulishRegular.woff2';
import MulishRegularSvg from '../fonts/MulishRegular.svg';

import MulishMediumEot from '../fonts/MulishMedium.eot';
import MulishMediumTtf from '../fonts/MulishMedium.ttf';
import MulishMediumWoff from '../fonts/MulishMedium.woff';
import MulishMediumWoff2 from '../fonts/MulishMedium.woff2';
import MulishMediumSvg from '../fonts/MulishMedium.svg';

import MulishBoldEot from '../fonts/MulishBold.eot';
import MulishBoldTtf from '../fonts/MulishBold.ttf';
import MulishBoldWoff from '../fonts/MulishBold.woff';
import MulishBoldWoff2 from '../fonts/MulishBold.woff2';
import MulishBoldSvg from '../fonts/MulishBold.svg';

import MulishExtraBoldEot from '../fonts/MulishExtraBold.eot';
import MulishExtraBoldTtf from '../fonts/MulishExtraBold.ttf';
import MulishExtraBoldWoff from '../fonts/MulishExtraBold.woff';
import MulishExtraBoldWoff2 from '../fonts/MulishExtraBold.woff2';
import MulishExtraBoldSvg from '../fonts/MulishExtraBold.svg';

import MulishBlackEot from '../fonts/MulishBlack.eot';
import MulishBlackTtf from '../fonts/MulishBlack.ttf';
import MulishBlackWoff from '../fonts/MulishBlack.woff';
import MulishBlackWoff2 from '../fonts/MulishBlack.woff2';
import MulishBlackSvg from '../fonts/MulishBlack.svg';

export const FontsNames = createGlobalStyle`
  @font-face {
    font-family: 'MulishLight';
    src: url(${MulishLightEot});
    src: local('MulishLight'), local('MulishLight'),
        url(${MulishLightWoff2}) format('woff2'),
        url(${MulishLightWoff}) format('woff'),
        url(${MulishLightTtf}) format('truetype'),
        url('${MulishLightSvg}#MulishLight') format('svg');
    font-weight: normal;
    font-style: normal;
  }
  @font-face {
    font-family: 'MulishRegular';
    src: url(${MulishRegularEot});
    src: local('MulishRegular'), local('MulishRegular'),
        url(${MulishRegularWoff2}) format('woff2'),
        url(${MulishRegularWoff}) format('woff'),
        url(${MulishRegularTtf}) format('truetype'),
        url('${MulishRegularSvg}#MulishRegular') format('svg');
    font-weight: normal;
    font-style: normal;
  }
  @font-face {
    font-family: 'MulishMedium';
    src: url(${MulishMediumEot});
    src: local('MulishMedium'), local('MulishMedium'),
        url(${MulishMediumWoff2}) format('woff2'),
        url(${MulishMediumWoff}) format('woff'),
        url(${MulishMediumTtf}) format('truetype'),
        url('${MulishMediumSvg}#MulishMedium') format('svg');
    font-weight: normal;
    font-style: normal;
  }
  @font-face {
    font-family: 'MulishBold';
    src: url(${MulishBoldEot});
    src: local('MulishBold'), local('MulishBold'),
        url(${MulishBoldWoff2}) format('woff2'),
        url(${MulishBoldWoff}) format('woff'),
        url(${MulishBoldTtf}) format('truetype'),
        url('${MulishBoldSvg}#MulishBold') format('svg');
    font-weight: bold;
    font-style: normal;
  }
  @font-face {
    font-family: 'MulishExtraBold';
    src: url(${MulishExtraBoldEot});
    src: local('MulishExtraBold'), local('MulishExtraBold'),
        url(${MulishExtraBoldWoff2}) format('woff2'),
        url(${MulishExtraBoldWoff}) format('woff'),
        url(${MulishExtraBoldTtf}) format('truetype'),
        url('${MulishExtraBoldSvg}#MulishExtraBold') format('svg');
    font-weight: bold;
    font-style: normal;
  }
  @font-face {
    font-family: 'MulishBlack';
    src: url(${MulishBlackEot});
    src: local('MulishBlack'), local('MulishBlack'),
        url(${MulishBlackWoff2}) format('woff2'),
        url(${MulishBlackWoff}) format('woff'),
        url(${MulishBlackTtf}) format('truetype'),
        url('${MulishBlackSvg}#MulishBlack') format('svg');
    font-weight: bold;
    font-style: normal;
  }
`
