import styled, {createGlobalStyle, css} from 'styled-components';
import { Link as GatsbyLink } from "gatsby";
import AniLink from "gatsby-plugin-transition-link/AniLink"
import { Container as ContainerStyled } from 'bloomer'
import {GlobalBackgroundColors, GlobalColors, GlobalFonts} from '../../styles/GlobalStyles';
import {CfgAniLink} from '../Menu/stylesItemMenuDesk';

export const GlobalStyleNavFullScreen = createGlobalStyle`
  #nav-fullscreen{
    position: fixed;
    width: 100%;
    height: 0;
    top: 0;
    left: 0;
    background-color: ${GlobalBackgroundColors.bgMenuMobile};
    border-bottom: 1px solid ${GlobalBackgroundColors.bgColorHeaderBorder};
    z-index: 99999999;
  }
  .cont-nav-mobile-header{
    width: 100%;
    height: 80px;
    background-color: ${GlobalBackgroundColors.bgColorHeader};
  }
  .cont-nav-fullscreen{
    height: 100%;
  }
  .nav-fullscreen-hidden{
    /*display: none;*/
  }
  .nav-fullscreen-show{
    /*display: block;*/
  }
  #nav-fullscreen {
    overflow-y: hidden;
    transition: 0.5s;
  }
  #nav-fullscreen a {
    text-decoration: none;
  }
 
  #nav-fullscreen .dark{
    background-color: ${GlobalBackgroundColors.bgPrimary};
  }
  #nav-fullscreen .light{
    background-color: ${GlobalBackgroundColors.bgSecondary};
  }
    
  @media screen and (max-height: 450px) {
    #nav-fullscreen {overflow-y: auto;}
  }
  /*@media screen and (min-height: 1024px) {
    #nav-fullscreen {
      height: 0% !important;
    }
  }*/
`

export const ContainerNavFullScreen = styled(ContainerStyled)`
  overflow: auto;
`

export const Nav = styled.nav`
  position: relative;
  width: 100%;
  height: auto;
  background: transparent;
  display: flex;
  flex-direction: column;
  z-index: 1000;
`
export const CfgLink = css`
  width: 100%;
  height: 100%;
  padding: 30px 20px;
  border-bottom: 1px solid ${GlobalColors.colorBorder};
  font-family: ${GlobalFonts.fontPrimary};
  color: ${GlobalColors.colorMenuMobilePrimary};
  display: inline-flex;
  align-items: center;
  justify-content: left;
  text-decoration: none;
  transition: 0.3s;

  &:hover{
    color: ${GlobalColors.colorMenuMobileSecondary};
  }
  
  &[aria-current]{
    color: ${GlobalColors.colorMenuMobileSecondary};
  }
  @media (min-width: 320px) and (max-width: 767px){
    font-size: 20px;
    line-height: 30px;
  }
  @media (min-width: 768px) and (max-width: 1024px){
    width: 100%;
    font-size: 25px;
    line-height: 35px;
  }
`
export const MyAniLink = styled(AniLink)`
  ${CfgLink}
`
export const BtnAniLink = styled.button`
  ${CfgLink}
`
export const ContBorderLogoFooter = styled.div`
  width: 100%;
  height: 1px;
  margin-top: 50px;
  border-top: 1px solid ${GlobalColors.colorMenuMobilePrimary};
`
export const ContLogoFooter = styled.div`
  position: relative;
  width: 210px;
  height: 57px;
  display: flex;
  align-items: center;
  justify-content: center;
`
export const ContCopyRight = styled.div`
  position: relative;
  width: 90%;
  height: auto;
  font-family: ${GlobalFonts.fontPrimary};
  font-size: 12px;
  color: ${GlobalColors.colorMenuMobilePrimary};
  display: flex;
  align-items: center;
  justify-content: center;
`
export const ItemLinksFooter = styled.div`
  position: relative;
  width: 90%;
  height: auto;
  
  display: flex;
  align-items: center;
  justify-content: center;
`
export const LinkFooter = styled(GatsbyLink)`
  font-family: ${GlobalFonts.fontPrimary};
  font-size: 10px;
  color: ${GlobalColors.colorMenuMobilePrimary};
`
