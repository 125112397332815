import React, {  Fragment, useEffect } from 'react';
import { Columns, Column } from 'bloomer';
import { IcoMenu } from "./ico-menu";
import { IcoCloseMenu } from "./ico-close-menu";
import {GlobalStyle} from "../../styles/GlobalStyles";
import {
  GlobalStyleNavFullScreen,
  ContainerNavFullScreen,
  Nav,
  ContLogoFooter,
  ContCopyRight,
  ItemLinksFooter, LinkFooter, ContBorderLogoFooter, BtnAniLink, MyAniLink
} from './styles';
import {Logo} from '../Logo';
import {LogoSecondary} from '../Logo/LogoSecondary';
import {ContLogo, ContLogoHigh} from '../Header/styles';
import scrollTo from 'gatsby-plugin-smoothscroll';

export const NavFullScreenOverlay = ({typeRouteMenu}) => {

  // console.log('typeRouteMenu =>', typeRouteMenu);
  const isBrowser = typeof window !== "undefined";

  const fnGoToElement = (el) => {
    console.log('el =>', el);
    if (isBrowser) {
      if(el === 'https://web.callmatik.com/panel/es/login'){
        window.location.href = el;
      } else if (el === 'https://blog.callmatik.com') {
        window.open(el, '_blank');
      } else if(el === '/registrate'){
        window.location.href = el;
      }
      setTimeout(function(){
        if(el !== 'https://blog.callmatik.com') {
          scrollTo(`#${el}`);
        }
      }, 1000);
    }
  }

  let handleMenuFullScreen = function(bolShowMenuResponsive, element) {
    console.log(element, 'a')
    let el;
    let cls;

    setTimeout(
      function() {
        el = document.getElementById('nav-fullscreen');
        el.classList.remove("nav-fullscreen-show");
        el.classList.remove("nav-fullscreen-hidden");

        cls = bolShowMenuResponsive
          ? "nav-fullscreen-show"
          : "nav-fullscreen-hidden";
        el.classList.add(cls);

        bolShowMenuResponsive
          ? document.getElementById("nav-fullscreen").style.height = "100%"
          : document.getElementById("nav-fullscreen").style.height = "0%";
      },
      100
    );

    fnGoToElement(element);
  }

  useEffect(() => {
    function handleResize() {
      if (isBrowser) {
        if(window.innerWidth >= 1024){
          document.getElementById("nav-fullscreen").style.height = "0%";
        }
        //console.log('resized to: ', window.innerWidth, 'x', window.innerHeight)
      }
    }
    if (isBrowser) {
      window.addEventListener('resize', handleResize)
    }
  });

  return (
    <Fragment>
      <GlobalStyleNavFullScreen/>
      <GlobalStyle/>

      <IcoMenu
        onClick={() => handleMenuFullScreen(true)}
      />

      <div id="nav-fullscreen" className={`nav-fullscreen-hidden`}>
        <ContainerNavFullScreen className="cont-nav-mobile-header">
          <Columns className="columns-responsive columns-margin-side-no full-width full-height">
            <Column className="global-columns-paddign-no flex full-height" isSize={{mobile: 12, tablet: 12, desktop: 3, widescreen: 3}}>
              <ContLogoHigh>
                <ContLogo>
                  <Logo />
                </ContLogo>
              </ContLogoHigh>
            </Column>
            <Column className="global-columns-paddign-no flex-center" isSize={{mobile: 2, tablet: 2, desktop: 0, widescreen: 0 }}>
              <IcoCloseMenu
                onClick={() => handleMenuFullScreen(false)}
              />
            </Column>
          </Columns>
        </ContainerNavFullScreen>
        <ContainerNavFullScreen className="cont-nav-fullscreen">
          <Columns className="columns-responsive">
            <Column className="padding-side-no" isSize={{mobile: 12, tablet: 12, desktop: 12, widescreen: 12 }}>
              {typeRouteMenu === '#'
                ? (
                  <Nav>
                    <BtnAniLink
                      to="/configplan"
                      onClick={() => handleMenuFullScreen(false, 'configplan')}
                    >
                      Configura tu Plan
                    </BtnAniLink>
                    <BtnAniLink
                      to="/why"
                      onClick={() => handleMenuFullScreen(false, 'why')}
                    >
                      ¿Por qué Callmatik?
                    </BtnAniLink>
                    <BtnAniLink
                      to="/solutions"
                      onClick={() => handleMenuFullScreen(false, 'solutions')}
                    >
                      Soluciones a tu medida
                    </BtnAniLink>
                    <BtnAniLink
                      to="/ourplans"
                      onClick={() => handleMenuFullScreen(false, 'ourplans')}
                    >
                      Nuestros planes
                    </BtnAniLink>
                    <BtnAniLink
                      to="https://web.callmatik.com/panel/es/login"
                      onClick={() => handleMenuFullScreen(false, 'https://web.callmatik.com/panel/es/login')}>
                      Clientes
                    </BtnAniLink>
                    <BtnAniLink
                      to="https://blog.callmatik.com"
                      onClick={() => handleMenuFullScreen(false, 'https://blog.callmatik.com')}>
                      Blog
                    </BtnAniLink>
                    <BtnAniLink
                      to="/registrate"
                      onClick={() => handleMenuFullScreen(false, '/registrate')}>
                      Contratar
                    </BtnAniLink>
                  </Nav>
                ) : (
                  <Nav>
                    <MyAniLink
                      to={`${typeRouteMenu}configplan`}
                      exit={{length: 0.5, state: {urlSection: `configplan`}}}
                      entry={{delay: 0.5, state: {urlSection: `configplan`}}}
                      state={{urlSection: `configplan`}}
                      onClick={() => fnGoToElement('configplan')}
                    >
                      Configura tu Plan
                    </MyAniLink>
                    <MyAniLink
                      to={`${typeRouteMenu}why`}
                      exit={{length: 0.5, state: {urlSection: `why`}}}
                      entry={{delay: 0.5, state: {urlSection: `why`}}}
                      state={{urlSection: `why`}}
                      onClick={() => fnGoToElement('why')}
                    >
                      ¿Por qué Callmatik?
                    </MyAniLink>
                    <MyAniLink
                      to={`${typeRouteMenu}solutions`}
                      exit={{length: 0.5, state: {urlSection: `solutions`}}}
                      entry={{delay: 0.5, state: {urlSection: `solutions`}}}
                      state={{urlSection: `solutions`}}
                      onClick={() => fnGoToElement('solutions')}
                    >
                      Soluciones a tu medida
                    </MyAniLink>
                    <MyAniLink
                      to={`${typeRouteMenu}ourplans`}
                      exit={{length: 0.5, state: {urlSection: `ourplans`}}}
                      entry={{delay: 0.5, state: {urlSection: `ourplans`}}}
                      state={{urlSection: `ourplans`}}
                      onClick={() => fnGoToElement('ourplans')}
                    >
                      Nuestros planes
                    </MyAniLink>
                    <MyAniLink
                      to={`https://web.callmatik.com/panel/es/login`}
                      exit={{length: 0.5, state: {urlSection: `contact`}}}
                      entry={{delay: 0.5, state: {urlSection: `contact`}}}
                      state={{urlSection: `contact`}}
                      onClick={() => fnGoToElement('https://web.callmatik.com/panel/es/login')}
                    >
                      Clientes
                    </MyAniLink>
                    <MyAniLink
                      to={`/registrate`}
                      exit={{length: 0.5, state: {urlSection: `contact`}}}
                      entry={{delay: 0.5, state: {urlSection: `contact`}}}
                      state={{urlSection: `contact`}}
                      onClick={() => fnGoToElement('/registrate')}
                    >
                      Contratar
                    </MyAniLink>
                  </Nav>
                )}
            </Column>
          </Columns>
          <Columns className="columns-responsive">
            <Column className="padding-side-no flex-center" isSize={{mobile: 12, tablet: 12, desktop: 12, widescreen: 12 }}>
              <ContBorderLogoFooter/>
            </Column>
            <Column className="padding-side-no flex-center" isSize={{mobile: 12, tablet: 12, desktop: 12, widescreen: 12 }}>
              <ContLogoFooter>
                <LogoSecondary/>
              </ContLogoFooter>
            </Column>
            <Column className="padding-side-no flex-center" isSize={{mobile: 12, tablet: 12, desktop: 12, widescreen: 12 }}>
              <ContCopyRight>
                © 2021 Callmatik, Inc. Mexico City
              </ContCopyRight>
            </Column>
          </Columns>
          <Columns className="columns-responsive">
            <Column className="padding-side-no flex-center" isSize={{mobile: 12, tablet: 12, desktop: 12, widescreen: 12 }}>
              <ItemLinksFooter>
                <LinkFooter
                  to="/terminos"
                  onClick={() => handleMenuFullScreen(false)}>
                  Términos
                </LinkFooter>
              </ItemLinksFooter>
              <ItemLinksFooter>
                <LinkFooter
                  to="/privacidad"
                  onClick={() => handleMenuFullScreen(false)}>
                  Privacidad
                </LinkFooter>
              </ItemLinksFooter>
              {/*<ItemLinksFooter>
                <LinkFooter
                  to="/accesabilidad"
                  onClick={() => handleMenuFullScreen(false)}>
                  Accesibilidad
                </LinkFooter>
              </ItemLinksFooter>*/}
            </Column>
          </Columns>
        </ContainerNavFullScreen>
      </div>
    </Fragment>
  )
}
