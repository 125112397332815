import {createGlobalStyle} from 'styled-components'


export const GlobalStyleLayout = createGlobalStyle`
  .layout{
    /*height: calc(100vh - 80px);*/
    //height: 100vh;
    height: 100%;
  }
  .wrapper-pages{
    position: relative;
    padding-top: 0;
    //height: calc(100vh - 95px);
    //height: 100vh;
    bottom: 0;
    z-index: 999;
    
    /*@media(min-width: 1024px) and (max-width: 1999px){
      height: calc(100vh - 95px);
      //height: 100vh;
    }*/
  }
  .layout-overflow-on{
    overflow: auto;
  }
  .layout-overflow-off{
    overflow: hidden;
  }

  #button_whatsapp{
    position: absolute;
    height: 80px;
    bottom: 0;
    right: 0;
    min-width: 60px;
    border-radius: 5px solid blue;
  }
  
  .right{
    right: 20px;
  }

  .title{
    min-width: 160px;
  }

  #button_floating {
      max-width: 300px;
      overflow: hidden;
      transition: all .4s ease-out;
      min-width: 60px;
      height: 60px;
      border-radius: 60px;
      cursor: pointer;
      box-shadow: 0 1px 6px rgba(0,0,0,.06), 0 2px 32px rgba(0,0,0,.16);
      position: fixed;
      bottom: 40px;
      background: #29323c;
      z-index: 9999;
  }

  .widget_bt1{
    background: #25d366!important;
  }

  #widget_logo {
    position: absolute;
    top: 16px;
    left: 15px!important;
    transition: all .3s ease;
    opacity: 1;
    width: 28px;
  }

  #floating_button_sirena_span {
    position: relative;
    top: 19px;
    font-size: 16px;
    color: #fff;
    font-weight: 500;
    display: block;
    float: left;
    box-sizing: border-box;
    padding-left: 60px;
    padding-right: 20px;
    transition: all .3s ease;
}

/*@media(min-width: 300px) and (max-width: 1023px){
  #floating_button_sirena_span{
    visibility: hidden;
  }
}*/
  
`