import React from 'react';
import {CiaFooter, ContainerFooter, ContLogoFooter, Footer, MenuFooter, MenuItemFooter, StylesFooter} from './styles';
import {Columns, Column, } from 'bloomer';
import {ContLogo} from '../Header/styles';
import {LogoSecondary} from '../Logo/LogoSecondary';
import icoPhone from '../../images/logo-secondary.svg';

export const FooterComp = () => {

  return (
    <Footer>
      <StylesFooter/>
      <ContainerFooter isFluid={false}>
        <Columns className="columns-responsive columns-margin-side-no full-width">
          <Column className="global-columns-paddign-no flex" isSize={{mobile: 12, tablet: 12, desktop: 3, widescreen: 3}}>
            <ContLogoFooter>
              <ContLogo>
                {/*<LogoSecondary />*/}
                <img src={icoPhone} alt={''}/>
              </ContLogo>
            </ContLogoFooter>
          </Column>

          <Column className="padding-side-no no-display-mobile" isSize={{mobile: 0, desktop: 9, widescreen: 9}}>
            <MenuFooter>
              <MenuItemFooter to={`/terminos`}>Términos</MenuItemFooter>
              {/*<MenuItemFooter to={`/api`}>API</MenuItemFooter>*/}
              <MenuItemFooter to={`/privacidad`}>Privacidad</MenuItemFooter>
              {/*<MenuItemFooter to={`/cookies`}>Cookies</MenuItemFooter>*/}
              {/*<MenuItemFooter to={`/divulgacion-responsable`} className={'link-width'}>Divulgación responsable</MenuItemFooter>*/}
              <CiaFooter>2024 Callmatik</CiaFooter>
            </MenuFooter>
          </Column>
          <Column className="padding-side-no no-display-desktop" isSize={{mobile: 0, desktop: 9, widescreen: 9}}>
            <MenuFooter>
              <CiaFooter>2024 Callmatik</CiaFooter>
            </MenuFooter>
            <MenuFooter>
              <MenuItemFooter to={`/terminos`}>Términos</MenuItemFooter>
              {/*<MenuItemFooter to={`/api`}>API</MenuItemFooter>*/}
              <MenuItemFooter to={`/privacidad`}>Privacidad</MenuItemFooter>
              {/*<MenuItemFooter to={`/cookies`}>Cookies</MenuItemFooter>*/}
              {/*<MenuItemFooter to={`/divulgacion-responsable`} className={'link-width'}>Divulgación responsable</MenuItemFooter>*/}
            </MenuFooter>
          </Column>
        </Columns>
      </ContainerFooter>
    </Footer>
  )
}
