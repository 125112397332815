import styled, {createGlobalStyle, css} from 'styled-components'
import { Link as GatsbyLink } from "gatsby";
import AniLink from "gatsby-plugin-transition-link/AniLink"
import {GlobalColors, GlobalFonts} from '../../styles/GlobalStyles';

export const GlobalStyle = createGlobalStyle`
  
`
export const LinkGatsby = styled(GatsbyLink)`
  /* Display & Box Model */
  display: flex;
  justify-content: center;
  flex-flow: wrap;
  width: 100%;
  height: 100%;
  padding: 20px 5px;

  /* Typografhy */
  text-decoration: none;
  font: 20px ${GlobalFonts.fontPrimary};
  color: ${props => props.color};
  
  &:hover{
    color: ${props => props.color};
    background-image: url(${props => props.src});
    background-repeat: no-repeat;
    background-position: center bottom;
  }
  
  &[aria-current]{
    color: ${props => props.color};
  }
  
  @media(min-width: 300px) and (max-width: 768px){
    font-size: 20px;
  }
  @media(min-width: 769px) and (max-width: 1023px){
    font-size: 20px;
  }
  @media(min-width: 1024px) and (max-width: 1215px){
    font-size: 20px;
  }
  @media(min-width: 1216px) and (max-width: 1407px){
    font-size: 20px;
  }
  @media(min-width: 1408px){
    font-size: 20px;
  }
`
export const CfgAniLink = css`
  width: 100%;
  height: 100%;

  /* Display & Box Model */
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: wrap;
  
  /* Typografhy */
  text-decoration: none;
  font: 15px ${GlobalFonts.fontBold};
  color: ${props => props.color};
  cursor: pointer;
  
  &:hover{
    color: ${props => (props.Bhover)?GlobalColors.colorLight:GlobalColors.colorPrimaryDark}
  }
  
  &[aria-current]{
    color: ${props => props.color};
  }
  
  @media(min-width: 300px) and (max-width: 767px){
    
  }
  @media(min-width: 768px) and (max-width: 1023px){
    
  }
  @media(min-width: 1024px) and (max-width: 1215px){
    padding: 10px;
    font-size: 13px;
    margin-right: 15px;
  }
  @media(min-width: 1216px) and (max-width: 1407px){
    padding: 10px;
    font-size: 15px;
    margin-right: 15px;
  }
  @media(min-width: 1408px) and (max-width: 1919px){
    padding: 15px;
    font-size: 15px;
    margin-right: 15px;
  }
  @media(min-width: 1920px){
    padding: 15px;
    font-size: 15px;
    margin-right: 15px;
  }
`
export const MyAniLink = styled(AniLink)`
  ${CfgAniLink}
`
export const BtnAniLink = styled.button`
  ${CfgAniLink}
`
