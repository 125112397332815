import styled from 'styled-components'
import { Link as GatsbyLink } from "gatsby";
import AniLink from "gatsby-plugin-transition-link/AniLink"

export const LinkGatsby = styled(GatsbyLink)`
  /* Display & Box Model */
  display: flex;
  justify-content: center;
  flex-flow: wrap;
  width: 100%;
  height: 100%;
`
export const MyAniLink = styled(AniLink)`
  width: 100%;
  height: 100%;
  flex-flow: wrap;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const Svg = styled.svg`
  position: relative;

  display: flex;
  height: auto;
  align-items: center;
  
  @media(min-width: 300px) and (max-width: 768px){
    width: 100%;
    height: 55px;
  }
  @media(min-width: 769px) and (max-width: 1023px){
    width: 100%;
    height: 55px;
  }
  @media(min-width: 1024px) and (max-width: 1215px){
    width: 100%;
    height: 55px;
  }
  @media(min-width: 1216px) and (max-width: 1407px){
    width: 100%;
    height: 55px;
  }
  @media(min-width: 1408px){
    width: 100%;
    height: 55px;
  }
`
