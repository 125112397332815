import * as React from "react"
import ItemMenuDesk from "./itemMenuDesk";

import {NavMenu, UlMenu, LiItemMenu, LiItemMenuRound, LiItemMenucolorSecondaryRound} from './styles';

const MenuDesk = ({itemsMenuDesk, typeRouteMenu}) => {

  /*console.log('itemsMenuDesk => ', itemsMenuDesk);
  console.log('nodes => ', itemsMenuDesk[0].itemMenu);*/

  return (
    <NavMenu>
      <UlMenu>
        {
          itemsMenuDesk.map((item, index) => {
            return(
              <div key={`cont-item-menu-${index}`}>
                {item.typeItem === 'normal'
                  ? <LiItemMenu
                      key={`item_menu_${index}`}
                    >
                      <ItemMenuDesk
                        typeRouteMenu={typeRouteMenu}
                        itemText={item.itemMenu}
                        linkTo={item.linkTo}
                        typeItem={item.typeItem}
                        showIcon={false}
                      />
                    </LiItemMenu>
                  : item.typeItem === 'round2'?
                  <LiItemMenucolorSecondaryRound
                      key={`item_menu_${index}`}
                    >
                      <ItemMenuDesk
                        typeRouteMenu={typeRouteMenu}
                        itemText={item.itemMenu}
                        linkTo={item.linkTo}
                        typeItem={item.typeItem}
                        showIcon={false}
                      />
                    </LiItemMenucolorSecondaryRound>
                  : item.typeItem === 'linkExt'?
                  <LiItemMenu
                      key={`item_menu_${index}`}
                    >
                      <ItemMenuDesk
                        typeRouteMenu={typeRouteMenu}
                        itemText={item.itemMenu}
                        linkTo={item.linkTo}
                        typeItem={item.typeItem}
                        showIcon={false}
                      />
                    </LiItemMenu>
                  :
                  <LiItemMenuRound
                      key={`item_menu_${index}`}
                    >
                      <ItemMenuDesk
                        typeRouteMenu={typeRouteMenu}
                        itemText={item.itemMenu}
                        linkTo={item.linkTo}
                        typeItem={item.typeItem}
                        showIcon={false}
                      />
                    </LiItemMenuRound>

                }
              </div>
            )
          })
        }
      </UlMenu>
    </NavMenu>
  )
}

export default MenuDesk
