import React from "react"
import PropTypes from "prop-types"
import {Columns, Column} from 'bloomer';
import {GlobalStyleHeader, ContainerHeader, ContLogo} from './styles';
import {Logo} from '../Logo';
import MenuDesk from "../Menu";
import {NavFullScreenOverlay} from "../NavFullScreenOverlay";

const Header = ({typeRouteMenu}) => {

  return(
    <header>
      <GlobalStyleHeader/>
      <ContainerHeader isFluid={false}>
        <Columns className="columns-responsive columns-margin-side-no full-width">

          <Column className="global-columns-paddign-no flex" isSize={{mobile: 12, tablet: 12, desktop: 3, widescreen: 3}}>
            <ContLogo>
              <Logo />
            </ContLogo>

            <NavFullScreenOverlay typeRouteMenu={typeRouteMenu}/>
          </Column>

          <Column className="padding-side-no no-display-mobile" isSize={{mobile: 0, desktop: 9, widescreen: 9}}>
            <MenuDesk
              typeRouteMenu={typeRouteMenu}
              itemsMenuDesk={
                [
                  {
                    itemMenu: 'Configura tu plan',
                    linkTo: 'configplan',
                    typeItem: 'normal',
                  },{
                    itemMenu: '¿Por qué Callmatik?',
                    linkTo: 'why',
                    typeItem: 'normal',
                  },{
                    itemMenu: 'Soluciones a tu medida',
                    linkTo: 'solutions',
                    typeItem: 'normal',
                  },{
                    itemMenu: 'Nuestros Planes',
                    linkTo: 'ourplans',
                    typeItem: 'normal',
                  },
                  {
                    itemMenu: 'Blog',
                    linkTo: 'https://blog.callmatik.com',
                    typeItem: 'linkExt',
                  },
                  {
                    itemMenu: 'Contratar',
                    linkTo: '/registrate',
                    typeItem: 'round2',
                  },
                  {
                    itemMenu: 'Clientes',
                    linkTo: 'https://web.callmatik.com/panel/es/login',
                    typeItem: 'round',
                  }
                ]
              }
            />
          </Column>
        </Columns>
      </ContainerHeader>
    </header>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

export default Header
