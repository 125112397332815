import React from 'react'
import { ContIcoMenuResponsive, Svg } from './styles-ico-menu'

export const IcoMenu = ({props, onClick}) => {

  return(
    <ContIcoMenuResponsive onClick={onClick}>
      <Svg
        width={51}
        height={25}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <path
          d="M44.976.05H17.18a1.635 1.635 0 000 3.27h27.796a1.635 1.635 0 000-3.27zM34.113 21.552H6.316a1.635 1.635 0 000 3.27h27.797a1.635 1.635 0 000-3.27zm15.052-10.707H1.635a1.635 1.635 0 100 3.27h47.53a1.635 1.635 0 000-3.27z"
          fill="#5B52BC"
        />
      </Svg>
    </ContIcoMenuResponsive>
  )
}
