import styled, {createGlobalStyle} from 'styled-components'
import { Container } from 'bloomer';
import {GlobalBackgroundColors} from '../../styles/GlobalStyles';

export const GlobalStyleHeader = createGlobalStyle`
  header{
    position: relative;
    width: 100%;
    height: 81px;
    background-color: ${GlobalBackgroundColors.bgColorHeader};
    border-bottom: 1px solid ${GlobalBackgroundColors.bgColorHeaderBorder};
  }
  #divTransitionPage {
    position: fixed;
    top: 0;
    left: 0;
  
    width: 0;
    height: 100vh;
  
    z-index: 99999;
    transition: width 0.5s;
  }
  .clsTransitionPageIn{
    width: 100% !important;
    background: red;
  }
  .clsTransitionPageOut{
    right: 0;
    width: 0 !important;
  }
  
  @media(min-width: 300px) and (max-width: 767px){
    header{
      height: 76px;
    }
  }
`

export const ContainerHeader = styled(Container)`
  position: relative;
  height: 76px;
  //padding-bottom: 15px;
  
  display: flex;
  align-items: center;
  justify-content: center;
`
export const ContLogoHigh = styled.div`
  position: relative;
  width: 100%;
  //height: 90px;
  height: 76px;
  display: flex;
  align-items: center;
  justify-content: center;
`
export const ContLogo = styled.div`
  position: relative;
  width: 200px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  
  @media(min-width: 300px) and (max-width: 767px){
    margin: 30px auto;
  }
  @media(min-width: 768px) and (max-width: 1023px){
    margin-left: 15px;
  }
`
export const ContNavBarMobile = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;

  @media (max-width: 1023px){
    display: none;
  }
`

export const ContRedes = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
`
