import styled, { createGlobalStyle } from 'styled-components'
import {GlobalColors} from '../../styles/GlobalStyles';

export const GlobalStyle = createGlobalStyle`
  .mnu-active{
    background-color: pink;
  }
`
export const NavMenu = styled.nav`
  display: flex;
  align-items: center;
  justify-content: right;
`
export const UlMenu = styled.ul`
  display: inline-flex;
  
  &>div{
    display: flex;
    align-items: center;
    justify-content: center;
  }
`
export const LiItemMenu = styled.li`
  min-width: 100px;
  text-align: center;
  
  &:hover,
  &:active{
    
  }
`
export const LiItemMenuRound = styled.li`
  min-width: 100px;
  text-align: center;
  background-color: #7C75C9;
  border-radius: 50px;
  margin-right: 15px;
  
  &:hover,
  &:active{
    
  }
`
export const LiItemMenucolorSecondaryRound = styled.li`
  min-width: 100px;
  text-align: center;
  background-color: ${GlobalColors.colorSecondary};
  border-radius: 50px;
  margin-right: 10px;
  
  &:hover,
  &:active{
    
  }
`
export const Ico = styled.div`
  color: #FF8675;
  width: 25px;
  height: 25px;
  &>img{
    width: 100%;
    height: 100%;
  }
`