import styled, { createGlobalStyle } from 'styled-components'
import {Container} from 'bloomer';
import {GlobalBackgroundColors, GlobalColors, GlobalFonts} from '../../styles/GlobalStyles';
import AniLink from "gatsby-plugin-transition-link/AniLink";

export const StylesFooter = createGlobalStyle`
  .link-width{
    width: 100% !important;
  }
  @media(min-width: 300px) and (max-width: 767px){
    .link-width{
      width: 100% !important;
    } 
  }
  @media(min-width: 768px) and (max-width: 1023px){
    .link-width{
      width: 100% !important;
    }
  }
  @media(min-width: 1024px) and (max-width: 1215px){
    
  }
  @media(min-width: 1216px) and (max-width: 1407px){
    
  }
  @media(min-width: 1408px) and (max-width: 1919px){
    
  }
  @media(min-width: 1920px){
    
  }
`
export const Footer = styled.footer`
  width: 100%;
  margin-top: 11px;
  background-color: ${GlobalBackgroundColors.bgTertiary};
  border-top: 1px solid ${GlobalBackgroundColors.bgPrimary};
  
  @media(min-width: 300px) and (max-width: 1023px){
    height: 160px;
  }
  @media(min-width: 1024px){
    height: 140px;
  }
  
  @media(min-width: 300px) and (max-width: 767px){
    height: auto;
    margin-top: 0px;
    padding-bottom: 30px;
  }
  @media(min-width: 768px) and (max-width: 1023px){
    height: 250px;
    margin-top: 1px;
    padding-bottom: 50px;
  }
  @media(min-width: 1024px) and (max-width: 1215px){
    margin-top: 0px;
  }
  @media(min-width: 1216px) and (max-width: 1407px){
    margin-top: 11px;
  }
  @media(min-width: 1408px) and (max-width: 1919px){
    margin-top: 11px;
  }
  @media(min-width: 1920px){
    margin-top: 11px;
  }
`
export const ContainerFooter = styled(Container)`
  position: relative;
  width: 70%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  
  @media(min-width: 300px) and (max-width: 767px){
    width: 100%;
  }
  @media(min-width: 768px) and (max-width: 1023px){
    
  }
  @media(min-width: 1024px) and (max-width: 1215px){
    
  }
  @media(min-width: 1216px) and (max-width: 1407px){
    
  }
  @media(min-width: 1408px) and (max-width: 1919px){
    
  }
  @media(min-width: 1920px){
    
  }
`
export const MenuFooter = styled.div`
  display: flex;
  flex-direction: row;
  
  @media(min-width: 300px) and (max-width: 767px){
    flex-wrap: wrap;
  }
  @media(min-width: 768px) and (max-width: 1023px){
    flex-wrap: wrap;
  }
  @media(min-width: 1024px) and (max-width: 1215px){
    margin-top: 30px;
    text-align: right;
  }
  @media(min-width: 1216px) and (max-width: 1407px){
    margin-top: 30px;
    text-align: right;
  }
  @media(min-width: 1408px) and (max-width: 1919px){
    margin-top: 30px;
    text-align: right;
  }
  @media(min-width: 1920px){
    margin-top: 30px;
    text-align: right;
  }
`
export const MenuFooterMobile = styled.div`
  display: flex;
  flex-direction: column;
`
export const MenuItemFooter = styled(AniLink)`
  width: 50%;
  height: 100%;

  /* Display & Box Model */
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: wrap;

  /* Typografhy */
  text-decoration: none;
  font: 15px ${GlobalFonts.fontPrimary};
  color: ${GlobalColors.colorMenuFooterPrimary};

  &:hover{
    color: ${GlobalColors.colorMenuMobileSecondary};
  }

  &[aria-current]{
    color: ${props => props.color};
  }

  @media(min-width: 300px) and (max-width: 767px){
    width: 50%;
    margin-bottom: 5px;
  }
  @media(min-width: 768px) and (max-width: 1023px){
    margin-bottom: 5px;
  }
  @media(min-width: 1024px) and (max-width: 1215px){
    padding: 10px 5px;
    font-size: 13px;
    justify-content: end;
  }
  @media(min-width: 1216px) and (max-width: 1407px){
    padding: 10px 5px;
    font-size: 15px;
    justify-content: end;
  }
  @media(min-width: 1408px) and (max-width: 1919px){
    padding: 10px 5px;
    font-size: 15px;
    justify-content: end;
  }
  @media(min-width: 1920px){
    padding: 10px 5px;
    font-size: 15px;
    justify-content: end;
  }
`
export const CiaFooter = styled.div`
  width: 100%;
  height: 100%;
  text-decoration: none;
  font: 15px ${GlobalFonts.fontPrimary};
  color: ${GlobalColors.colorMenuFooterPrimary};

  @media(min-width: 300px) and (max-width: 767px){
    margin-bottom: 20px;
    text-align: center;
  }
  @media(min-width: 768px) and (max-width: 1023px){
    margin-bottom: 20px;
    text-align: center;
  }
  @media(min-width: 1024px) and (max-width: 1215px){
    width: 70%;
    padding: 10px 5px;
    font-size: 13px;
  }
  @media(min-width: 1216px) and (max-width: 1407px){
    width: 70%;
    padding: 10px 5px;
    font-size: 15px;
  }
  @media(min-width: 1408px) and (max-width: 1919px){
    width: 70%;
    padding: 10px 23px 10px 5px;
    font-size: 15px;
  }
  @media(min-width: 1920px){
    width: 70%;
    padding: 10px 60px 10px 5px;
    font-size: 15px;
  }
`
export const ContLogoFooter = styled.div`
  position: relative;
  width: 100%;
  height: 90px;
  margin-top: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
`