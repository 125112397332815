import React from 'react'
import { ContIcoMenuCloseResponsive, Svg } from './styles-ico-close-menu'

export const IcoCloseMenu = ({props, onClick}) => {

  return(
    <ContIcoMenuCloseResponsive onClick={onClick}>
      <Svg
        width={29}
        height={29}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M27.988 26.747a1.319 1.319 0 11-1.866 1.866L14.187 16.679 2.253 28.613a1.316 1.316 0 01-1.866 0 1.319 1.319 0 010-1.866L12.32 14.812.387 2.877a1.319 1.319 0 111.866-1.866l11.934 11.935L26.122 1.01a1.319 1.319 0 111.866 1.866L16.054 14.812l11.934 11.935z"
          fill="#5B52BC"
        />
      </Svg>
    </ContIcoMenuCloseResponsive>
  )
}
