import styled, { createGlobalStyle, css } from 'styled-components';
import AniLink from "gatsby-plugin-transition-link/AniLink";

export const GlobalBackgroundColors = {
  bgPrimary: "#FBFBFE",
  bgSecondary: "#E6E6E6",
  bgTertiary: "#5B52BC",
  bgQuaternary: "#FF8675",
  bgGreyDark: "#383e40",
  bgGreyLight: "#FBFBFE",
  bgMenuMobile: "#5B52BC",
  //bgContentLight: `#F67879`,
  bgContentLight: `#5B52BC`,
  bgContentLightest: `#FDDAD2`,
  bgColorApp: `#f7f9fc`,
  bgColorHeader: `#ffffff`,
  bgColorHeaderBorder: `#F4F3FF`,
  bgColorMenu: `#f7f9fc`,
  bgColorMain: `#ffffff`,
  bgColorFrame: `#f7f9fb`,
  bgColorTextFound: `#f39c12`,
  bgSpeechBubble: `#24242a`,
  bgBorderSpeechBubble: `#bab5b0`,
}

export const GlobalColors = {
  colorMenuPrimary: `#828282`,
  colorMenuSecondary: `#1D1D1B`,
  colorMenuMobilePrimary: `#FFFFFF`,
  colorMenuMobileSecondary: `#FF8675`,
  colorBorder: `#7C75C9`,
  colorBorderDark: `#9da1a4`,
  colorPrimary: `#5B52BC`,
  colorPrimaryLight: `#9D97D7`,
  colorPrimaryDark: `#e67e22`,
  colorSecondary: `#FF8675`,
  colorSecondaryLight: `#FFB6AC`,
  colorSuccess: `#20bf6b`,
  colorDanger: `#eb3b5a`,
  colorDangerDark: `#c5314b`,
  colorWarning: `#f7b731`,
  colorInfo: `#8854d0`,
  colorLight: `#f7f9fc`,
  colorDark: `#4F4F4F`,
  colorShadowRGB: `75, 101, 132`,
  colorLink: `#eb3b5a`,
  colorLinkHover: `#eb3b5a`,
  colorBtnTextPrimary: `#FFFFFF`,
  colorBtnTextPrimaryHover: `#FFFFFF`,
  colorText: `#4F4F4F`,
  colorTextDiscreet: `#BDBAE4`,
  colorMenuFooterPrimary: `#F4F3FF`,
}

export const GlobalFonts = {
  fontPrimary: 'MulishRegular',
  fontSecondary: 'MulishMedium',
  fontLight: 'MulishLight',
  fontRegular: 'MulishRegular',
  fontMedium: 'MulishMedium',
  fontBold: 'MulishBold',
  fontExtraBold: 'MulishExtraBold',
  fontBlack: 'MulishBlack',
}

export const GlobalStyle = createGlobalStyle`
  html {
    box-sizing: border-box;
    background: ${GlobalBackgroundColors.bgPrimary};
    overflow: auto;
  }

  *, *:before, *:after {
    box-sizing: inherit;
  }

  ul, li, h1, h2, h3, p, button { margin: 0; padding: 0; }
  ul { list-style: none; }
  button { background: transparent; border: 0; outline: 0 }
  *:focus {
    outline: none;
  }
  body {
    width: 100%;
    height: 100vh;
    margin: 0 auto;
    overscroll-behavior: none;
    font-family: 'Mulish', sans-serif;
  }
  #root{
    height: 100vh;
  }
  .router{
    position: unset !important;
  }
  .wrapper{
    height: 100%;
  }
  .columns-row {
    flex-direction: row;
  }
  .columns {
    margin-left: 0 !important; 
    margin-right: 0 !important;
  }
  .columns-reorder-desktop {
    display: flex;
    flex-direction: column-reverse;
  }
  @media(min-width: 300px) and (max-width: 1023px){
    .columns-reorder-mobile {
      display: flex;
      flex-direction: column-reverse;
    }
  }
  @media screen and (min-width: 769px){
    .columns:not(.is-desktop) {
      display: block !important;
    }  
  }
  @media screen and (min-width: 1024px){
    .columns:not(.is-desktop) {
      display: flex !important;
    }  
  }  
  
  .margin-no{
    margin: 0;
  }
  .margin-top-main{
    margin-top: 50px;
  }
  .margin-top{
    margin-top: 10px;
  }
  .margin-bottom-main{
    margin-bottom: 50px;
  }
  .margin-bottom{
    margin-top: 10px;
  }
  .columns-padding-no > .columns > .column,
  .padding-no{
    padding: 0 !important;
  }
  .padding-side-no{
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  
  .padding-3{
    padding: 3px;
  }

  .cont-page{
    width: 100%;
    height: 100%;
  }
  
  .ancho-100,
  .full-width{
    width: 100%;
  }
  
  .alto-100,
  .full-height{
    height: 100%;
  }

  .full-heightbar{
    height: 110%;
  }

  .align-left{
    text-align: left;
  }
  .vertical-center{
    display: flex;
    align-items: center;
  }
  .horizontal-center{
    display: flex;
    justify-content: center;
  }
  .absolute-center{
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .z-index-low{
    z-index: 1
  }
  .z-index-high{
    z-index: 99
  }
  
  #divTransitionPage {
    position: fixed;
    top: 0;
    left: 0;
    width: 0;
    height: 100vh;
    z-index: 99999;
    transition: width 0.5s;
  }
  .clsTransitionPageIn{
    width: 100% !important;
    background: red;
  }
  .clsTransitionPageOut{
    right: 0;
    
    width: 0 !important;
  }
  
  #app {
    box-shadow: 0 0 10px rgba(0, 0, 0, .05);
    overflow-x: hidden;
    min-height: 100vh;
    padding-bottom: 10px;
  }
  .field-label{
    flex-grow: 2;  
  }
  
  .container.is-fluid{
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
  .columns {
    margin-top: 0 !important;
  }
  .columns:not(:last-child) {
    margin-bottom: 0 !important;
  }
  .row-reverse{
    display: flex;
    flex-direction: row-reverse;
  }
  .columns-paddign-no{
    height: 100%;
    @media(min-width: 300px) and (max-width: 768px){
      padding-left: 0px !important;
      padding-right: 0px !important;
    }
  }
  .columns-margin-side-no{
    margin-left: 0px !important;
    margin-right: 0px !important
  }
  
  .global-columns-paddign-no{
    padding: 0px !important;
  }
  .overflow-unset{
    overflow: unset !important;
  }
 
  .flex{
    display: flex !important;
  }
  .flex-center{
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
  }

  .display-contents{
    display: contents;
  }
  
  .transition-group {
    flex: 1;
    position: relative;
  }
  
  .router {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }
    
  .fade-enter {
    opacity: 0;
    z-index: 1;
  }
  
  .fade-enter.fade-enter-active {
    opacity: 1;
    transition: opacity 350ms ease-in;
  }
  
  .dark{
    background-color: ${GlobalBackgroundColors.bgPrimary};
  }
  .light{
    background-color: ${GlobalBackgroundColors.bgSecondary};
  }
  .fontColorThemeDark{
    color: ${GlobalBackgroundColors.bgSecondary};
  }
  .fontColorThemeLight{
    background-color: ${GlobalBackgroundColors.bgPrimary};
  }
  
  .container-animated{
    > .img-animated{
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
  
  
  /* Fix */
  .svg-inline--fa.fa-w-16{
    width: 100% !important;
  }
  
  
  
  @media(min-width: 300px) and (max-width: 1024px){
    .columns {
      margin-left: 0 !important;
      margin-right: 0 !important;
    }
    .columns:last-child {
      margin-bottom: 0;
    }
  }
  @media(max-width: 768px){
    .column.is-5-mobile,
    .column.is-6-mobile{
      padding-top: .75rem;
      float: left;
    }
  }
  
  @media (min-width: 1023px){
    .display-mobile{
      display: flex !important;
    }
    .no-display-desktop{
      display: none !important;
    }
  }
  @media (max-width: 1023px){
    .columns-responsive{
      
    }
    .no-display-mobile{
      display: none !important;
    }
  }
  @media (min-width: 1024px){
    .no-display-desk{
      display: none;
    }
  }
  
  @media(min-width: 300px) and (max-width: 767px){
    .columns-reorder-mobile {
      flex-direction: column-reverse;
      display: flex;
    }
  }
  @media(min-width: 768px) and (max-width: 1023px){
    .columns-reorder-mobile {
      flex-direction: column-reverse;
      display: flex;
    }
  }
`
const baseStylesBtnPrimary = css`
  width: 100%;
  min-width: 100px;
  padding: 15px 30px;
  background-color: ${GlobalColors.colorSecondary};
  border-radius: 50px;
  font-family: ${GlobalFonts.fontPrimary};
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 26px;
  text-align: center;
  color: ${GlobalColors.colorBtnTextPrimary};
  cursor: pointer;

  box-shadow: 0px 8px 28px -6px rgba(124, 117, 201, 0.2), 0px 18px 88px -4px rgba(124, 117, 201, 0.2);
  
  &:disabled{
    background-color: ${GlobalColors.colorLight};
    color: ${GlobalColors.colorSecondary};
  }
  
  &:hover,
  &:active{
    background-color: ${GlobalColors.colorSecondary};
    color: ${GlobalColors.colorBtnTextPrimary};
  }
  
  @media(min-width: 300px) and (max-width: 767px){
    font-size: 12px;
  }
  @media(min-width: 768px) and (max-width: 1023px){
    font-size: 14px;
  }
  @media(min-width: 1024px) and (max-width: 1215px){
    font-size: 14px;
  }
  @media(min-width: 1216px) and (max-width: 1407px){
    font-size: 16px;
  }
  @media(min-width: 1408px) and (max-width: 1919px){
    font-size: 18px;
  }
  @media(min-width: 1920px){
    font-size: 18px;
  }
`;
export const BtnPrimary = styled.button`
  ${baseStylesBtnPrimary}
`
export const BtnPrimaryDisabled = styled.button`
  ${baseStylesBtnPrimary};
  
  background-color: ${GlobalColors.colorLight};
  color: ${GlobalColors.colorSecondary};
`
export const ABtnPrimary = styled(AniLink)`
  ${baseStylesBtnPrimary}
`
export const ABtnPrimaryPlan = styled.button`
  ${baseStylesBtnPrimary}
`
export const ABtnPrimaryTwo = styled(AniLink)`
  min-width: 100px;
  padding: 15px 30px;
  background-color: ${GlobalColors.colorSecondary};
  border-radius: 50px;
  font-family: ${GlobalFonts.fontPrimary};
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 26px;
  text-align: center;
  color: ${GlobalColors.colorBtnTextPrimary};
  cursor: pointer;
  &:disabled{
    background-color: ${GlobalColors.colorLight};
    color: ${GlobalColors.colorSecondary};
  }
  
  &:hover,
  &:active{
    background-color: ${GlobalColors.colorSecondary};
    color: ${GlobalColors.colorBtnTextPrimary};
  }
  box-shadow: 0px 8px 28px -6px rgba(124, 117, 201, 0.2), 0px 18px 88px -4px rgba(124, 117, 201, 0.2);
`
export const ContColumnInner = styled.div`
  @media(min-width: 300px) and (max-width: 767px){
    width: 100%;
  }
  @media(min-width: 768px) and (max-width: 1023px){
    width: 100%;
  }
  @media(min-width: 1024px) and (max-width: 1215px){
    width: 100%;
  }
  @media(min-width: 1216px) and (max-width: 1407px){
    width: 80%;
  }
  @media(min-width: 1408px) and (max-width: 1919px){
    width: 80%;
  }
  @media(min-width: 1920px){
    width: 80%;
  }
`
export const ContColumnsInLine = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
`
export const ContIntoCellVL = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
`
export const TitleSection = styled.div`
  top: ${props => props.top};
  font: 56px ${GlobalFonts.fontBlack};
  color: ${props => props.color ? props.color : GlobalColors.colorPrimary};
  font-style: normal;
  font-weight: 800;
  line-height: 46px;
  text-align: center;
  
  &>span{
    color: ${GlobalColors.colorSecondary};
  }
  
  @media(min-width: 300px) and (max-width: 767px){
    font-size: 36px;
  }
  @media(min-width: 768px) and (max-width: 1023px){
    font-size: 54px;
  }
  @media(min-width: 1024px) and (max-width: 1215px){
    font-size: 56px;
  }
  @media(min-width: 1216px) and (max-width: 1407px){
    font-size: 56px;
  }
  @media(min-width: 1408px) and (max-width: 1919px){
    font-size: 56px;
  }
  @media(min-width: 1920px){
    font-size: 56px;
  }
`

export const TitleSectionH1 = styled.h1`
  top: ${props => props.top};
  font: 56px ${GlobalFonts.fontBlack};
  color: ${props => props.color ? props.color : GlobalColors.colorPrimary};
  font-style: normal;
  font-weight: 800;
  line-height: 46px;
  text-align: center;
  
  &>span{
    color: ${GlobalColors.colorSecondary};
  }
  
  @media(min-width: 300px) and (max-width: 767px){
    font-size: 36px;
  }
  @media(min-width: 768px) and (max-width: 1023px){
    font-size: 54px;
  }
  @media(min-width: 1024px) and (max-width: 1215px){
    font-size: 56px;
  }
  @media(min-width: 1216px) and (max-width: 1407px){
    font-size: 56px;
  }
  @media(min-width: 1408px) and (max-width: 1919px){
    font-size: 56px;
  }
  @media(min-width: 1920px){
    font-size: 56px;
  }
`

export const PieTitleSection = styled.div`
  width: 100%;
  margin: 0 auto;
  font: 18px ${GlobalFonts.fontRegular};
  color: ${GlobalColors.colorText};
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  text-align: center;
`
export const PieTitleSectionWhite = styled.div`
  width: 100%;
  margin: 0 auto;
  font: 18px ${GlobalFonts.fontRegular};
  color: #FFF;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  text-align: center;
`
export const TitleSectionMini = styled.div`
  top: ${props => props.top};
  margin-bottom: 15px;
  font: 36px ${GlobalFonts.fontBold};
  color: ${GlobalColors.colorPrimary};
  font-style: normal;
  font-weight: 800;
  line-height: 36px;
  
  &>span{
    color: ${GlobalColors.colorSecondary};
  }
  
  @media(min-width: 300px) and (max-width: 767px){
    font-size: 36px;
    text-align: center;
  }
  @media(min-width: 768px) and (max-width: 1023px){
    font-size: 34px;
    text-align: center;
  }
  @media(min-width: 1024px) and (max-width: 1215px){
    font-size: 36px;
  }
  @media(min-width: 1216px) and (max-width: 1407px){
    font-size: 36px;
  }
  @media(min-width: 1408px) and (max-width: 1919px){
    font-size: 36px;
  }
  @media(min-width: 1920px){
    font-size: 36px;
  }
`
export const TitleSectionMiniMobile = styled.div`
  top: ${props => props.top};
  margin-bottom: 15px;
  font: 36px ${GlobalFonts.fontBold};
  color: ${GlobalColors.colorPrimary};
  font-style: normal;
  font-weight: 800;
  line-height: 36px;
  
  &>span{
    color: ${GlobalColors.colorSecondary};
  }
  
  @media(min-width: 300px) and (max-width: 767px){
    font-size: 24px;
    text-align: left;
  }
  @media(min-width: 768px) and (max-width: 1023px){
    font-size: 28px;
    text-align: left;
  }
  @media(min-width: 1024px) and (max-width: 1215px){
    font-size: 36px;
  }
  @media(min-width: 1216px) and (max-width: 1407px){
    font-size: 36px;
  }
  @media(min-width: 1408px) and (max-width: 1919px){
    font-size: 36px;
  }
  @media(min-width: 1920px){
    font-size: 36px;
  }
`
export const PieTitleSectionMini = styled.div`
  margin: 0 auto;
  font: 18px ${GlobalFonts.fontRegular};
  color: ${GlobalColors.colorMenuPrimary};
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  
  @media(min-width: 300px) and (max-width: 1023px){
    text-align: center;
  }
`
export const PieTitleSectionMiniMobile = styled.div`
  margin: 0 auto;
  font: 18px ${GlobalFonts.fontRegular};
  color: ${GlobalColors.colorMenuPrimary};
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  
  @media(min-width: 300px) and (max-width: 1023px){
    font: 16px ${GlobalFonts.fontRegular};
    text-align: left;
  }
`
export const TitleSectionSecondary = styled.div`
  margin: 10px auto 10px;
  padding-left: 10px;
  font: 18px ${GlobalFonts.fontRegular};
  color: ${GlobalColors.colorPrimary};
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
`
export const Linea = styled.div`
  width: 100%;
  height: 1px;
  margin: 15px 0;
  background-color: ${GlobalColors.colorTextDiscreet};
`
export const Separador = styled.div`
  width: 10px;
  height: 100%;
`
export const ContImportantElement = styled.div`
  width: 100%;
  height: auto;
  background-color: ${GlobalColors.colorMenuFooterPrimary};
  border: 1px solid ${GlobalColors.colorTextDiscreet};
  border-radius: 8px;
  
  & div,
  & span,
  & label{
    font: 16px ${GlobalFonts.fontPrimary};
    font-style: normal;
    font-weight: 600;
    line-height: 22px;
    color: ${GlobalColors.colorPrimary};
  }
`
export const Checkbox = styled.input`
  margin-right: 10px;
`
export const TitlePlanSummary = styled.div`
  margin-bottom: 10px;
  font: 16px ${GlobalFonts.fontPrimary};
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  color: ${GlobalColors.colorMenuPrimary};
`
export const NamePlanSummary = styled.div`
  font: 24px ${GlobalFonts.fontBold};
  font-style: normal;
  font-weight: 600;
  line-height: 28px;
  color: ${GlobalColors.colorPrimary};
`
